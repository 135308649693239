
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
    font-family: "Roboto", sans-serif;
    background-color: #fff;
}

p {
    color: #b3b3b3;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Roboto", sans-serif;
}

a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

a:hover {
    text-decoration: none !important;
}

.content {
    padding: 7rem 0;
}

h2 {
    font-size: 20px;
}

.half,
.half .container>.row {
    height: 100vh;
}

@media (max-width: 991.98px) {
    .half .bg {
        height: 500px;
    }
}

.half .contents,
.half .bg {
    width: 50%;
}

@media (max-width: 1199.98px) {
    .half .contents,
    .half .bg {
        width: 100%;
    }
}

.half .contents .form-group,
.half .bg .form-group {
    margin-bottom: 0;
    border: 1px solid #efefef;
    padding: 15px 15px;
    border-bottom: none;
}

.half .contents .form-group.first,
.half .bg .form-group.first {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.half .contents .form-group.last,
.half .bg .form-group.last {
    border-bottom: 1px solid #efefef;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.half .contents .form-group label,
.half .bg .form-group label {
    font-size: 12px;
    display: block;
    margin-bottom: 0;
    color: #b3b3b3;
}

.half .contents .form-control,
.half .bg .form-control {
    border: none;
    padding: 0;
    font-size: 20px;
    border-radius: 0;
}

.half .contents .form-control:active,
.half .contents .form-control:focus,
.half .bg .form-control:active,
.half .bg .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.half .bg {
    background-size: cover;
    background-position: center;
}

.half a {
    color: #888;
    text-decoration: underline;
}

.half .btn {
    height: 54px;
    padding-left: 30px;
    padding-right: 30px;
}

.half .forgot-pass {
    position: relative;
    top: 2px;
    font-size: 14px;
}

.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
}

.control .caption {
    position: relative;
    top: .2rem;
    color: #888;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
    border-radius: 4px;
}

.control--radio .control__indicator {
    border-radius: 50%;
}

.control:hover input~.control__indicator,
.control input:focus~.control__indicator {
    background: #ccc;
}

.control input:checked~.control__indicator {
    background: #007bff;
}

.control:hover input:not([disabled]):checked~.control__indicator,
.control input:checked:focus~.control__indicator {
    background: #1a88ff;
}

.control input:disabled~.control__indicator {
    background: #e6e6e6;
    opacity: 0.9;
    pointer-events: none;
}

.control__indicator:after {
    font-family: 'icomoon';
    content: '\e5ca';
    position: absolute;
    display: none;
    font-size: 16px;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.control input:checked~.control__indicator:after {
    display: block;
    color: #fff;
}

.control--checkbox .control__indicator:after {
    top: 50%;
    left: 50%;
    margin-top: -1px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.control--checkbox input:disabled~.control__indicator:after {
    border-color: #7b7b7b;
}

.control--checkbox input:disabled:checked~.control__indicator {
    background-color: #7e0cf5;
    opacity: .2;
}

.googlebutton{
    padding: 10px 10px;
    font-size: 18px;
    background-color: white;
    border-radius: 10px;
    border:none;
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
   margin-left: 25%;
}

.customstyleforpasserror{
    margin-top: -10px;
    margin-bottom: 10px;
}


.hr-1 {
    padding: 0;
    border: none;
    border-top: 1px solid black;
    color: black;
    text-align: center;    
    font-size:16px;
    font-weight: 500;
}
.hr-1:after {
    content: attr(data-content);
    display: inline-block;
    position: relative; 
    top: -0.8em; 
    padding: 0 1em;
    background: #ffffff;
}




@media only screen and (max-width: 400px) {
   .googlebutton{
    margin-left: 15%;
   }
    
  }



  @media only screen and (min-width: 992px) and (max-width: 1400px) {
    .googlebutton{
     margin-left: 15%;
    }
     
   }
   
   @media only screen and (min-width: 1700px) and (max-width: 2199px) {
    .googlebutton{
     margin-left: 30%;
    }
     
   }


   @media only screen and (min-width: 2200px) {
    .googlebutton{
     margin-left: 35%;
    }
     
   }


   svg {
    width: 24px;
    height: 24px;
  }


  .login-with-google-btn {
    transition: background-color .3s, box-shadow .3s;
    margin-top: 25px; 
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    
    color: #757575;
    font-size: 14px;
    font-weight: 500;
   
    
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
    
    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
    }
    
    &:active {
      background-color: #eeeeee;
    }
    
    &:focus {
      outline: none;
      box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
    }
    
  }
  